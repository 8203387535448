<template>
  <div class="split-bg">
    <!--begin::Main-->
    <div class="d-flex flex-column flex-root">
      <!--begin::Login-->
      <div class="login login-1 login-signin-on d-flex flex-column flex-lg-row flex-column-fluid bg-white" id="kt_login">
        <!--begin::Aside-->
        <div class="login-aside flex-row-auto p-10 p-lg-10 bg-primary d-none d-lg-flex min-vh-100 max-w-50">
          <!--begin: Aside Container-->
          <div class="d-flex flex-row-fluid flex-column justify-content-between">
            <!--begin: Aside header-->
            <a href="#" class="flex-column-auto mt-5 pb-lg-0 pb-10">
              <img :src="logo.url" class="h-70 max-h-70px" alt="" />
            </a>
            <!--end: Aside header-->
            <!--begin: Aside content-->
            <div class="flex-column-fluid d-flex flex-column justify-content-center">
              <h3 class="font-size-h1 font-weight-bolder mb-5 text-white">
                {{ $t('login.title') }}
              </h3>
              <p class="text-white opacity-80">
                {{ $t('login.sub_title') }}
              </p>
            </div>
            <!--end: Aside content-->
            <!--begin: Aside footer for desktop-->
            <div class="d-none flex-column-auto d-lg-flex justify-content-between mt-10">
              <div class="opacity-70 font-weight-bold text-white">
                {{ new Date().getFullYear() }}{{ $t('copyright.title') }}
              </div>
              <div class="d-flex">
                <!--<a href="#" class="text-white">Политика конфиденциальности</a>-->
              </div>
            </div>
            <!--end: Aside footer for desktop-->
          </div>
          <!--end: Aside Container-->
        </div>
        <!--begin::Aside-->
        <!--begin::Content-->
        <div class="d-flex flex-column flex-row-fluid position-relative p-7 overflow-hidden min-vh-100">
          <!--begin::Content header-->
          <div class="btn-toolbar justify-content-end">
            <div class="btn-group btn-group-sm" role="group">
              <button type="button" :class="this.$i18n.locale === 'en' ? 'btn-primary active' : 'btn-default'"
                      class="btn" @click="switchLocale('en')">EN</button>
              <button type="button" :class="this.$i18n.locale === 'ru' ? 'btn-primary active' : 'btn-default'"
                      class="btn" @click="switchLocale('ru')">RU</button>
            </div>
            <div v-if="selfRegistration" class="mt-2 ml-5">
              <span class="font-weight-bolder text-dark-50">
                {{ $t('login.have_account') }}
              </span>
              <a href="/signup" class="font-weight-bolder ml-2" id="kt_login_signup">
                {{ $t('login.register') }}
              </a>
            </div>
          </div>
          <!--end::Content header-->
          <div v-if="error" class="alert alert-danger" role="alert">
            {{error}}
          </div>
          <!--begin::Content body-->
          <div class="d-flex flex-column-fluid flex-center mt-30 mt-lg-0">

            <!--begin::Signin-->
            <div class="login-form login-signin w-100 max-w-400px">
              <div class="text-center mb-10 mb-lg-20">
                <h3 class="font-size-h1 font-weight-bolder">
                  {{ $t('login.login') }}
                </h3>
                <p class="text-muted font-weight-bolder">
                  {{ $t('login.hint') }}
                </p>
              </div>

              <!--begin::Form-->
              <form v-if="!isAuthenticated" class="form" novalidate="novalidate" id="kt_login_signin_form"
                    ref="form" @keypress.enter.prevent="login">
                <div class="form-group">
                  <input class="form-control form-control-solid h-auto py-5 px-6" type="email"
                         :placeholder="$t('login.email')" autocomplete="off" v-model="username"
                         v-on:keyup.enter="login"/>
                </div>
                <div class="form-group">
                  <input class="form-control form-control-solid h-auto py-5 px-6" :class="login_error ? 'is-invalid' : ''" type="password"
                         :placeholder="$t('login.password')" name="password" autocomplete="off"  v-model="password"
                         v-on:keyup.enter="login"/>
                  <span v-if="login_error" class="text-danger">{{login_error}}</span>
                </div>
                <!--begin::Action-->
                <div class="form-group d-flex flex-wrap justify-content-between align-items-center">
                  <a href="#" class="text-dark-50 text-hover-primary my-3 mr-2"
                     @click="modal_reset_pasword_show = true">{{ $t('login.forgot_pass') }}</a>
                  <button v-if="!loading" type="button" id="kt_login_signin_submit"
                          class="btn btn-primary font-weight-bold px-9 py-4 my-3"
                          @click="login">{{ $t('login.log_in') }}</button>
                  <button v-if="loading" type="button" disabled
                          class="btn btn-primary font-weight-bold px-9 py-4 my-3"
                          @click="login">{{ $t('login.loading') }}</button>
                </div>
                <!--end::Action-->
              </form>
              <button v-else type="button"
                      class="btn btn-info font-weight-bold px-9 py-4 my-3"
                      @click="goHome">{{ $t('login.log_in') }}</button>
              <!--end::Form-->
            </div>
            <!--end::Signin-->
          </div>
          <!--end::Content body-->
          <!--begin::Content footer for mobile-->
          <div class="d-flex d-lg-none flex-column-auto flex-column flex-sm-row justify-content-between align-items-center mt-5 p-5">
            <div class="text-dark-50 font-weight-bold order-2 order-sm-1 my-2">{{ new Date().getFullYear() }}{{ $t('copyright.title') }}</div>
<!--            <div class="d-flex order-1 order-sm-2 my-2">-->
<!--              <a href="#" class="text-dark-75 text-hover-primary">Privacy</a>-->
<!--              <a href="#" class="text-dark-75 text-hover-primary ml-4">Legal</a>-->
<!--              <a href="#" class="text-dark-75 text-hover-primary ml-4">Contact</a>-->
<!--            </div>-->
          </div>
          <!--end::Content footer for mobile-->
        </div>
        <!--end::Content-->
      </div>
      <!--end::Login-->
    </div>
    <!--end::Main-->


    <!-- Modal-->
    <div class="modal fade show" :class="modal_reset_pasword_show ? 'show_modal' : ''">
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel">{{ $t('login.password_recovery') }}</h5>
            <button type="button" class="close" @click="modal_reset_pasword_show = false" aria-label="Close">
              <i aria-hidden="true" class="ki ki-close"></i>
            </button>
          </div>
          <div class="modal-body">
            {{ $t('login.recovery_title') }}
            <form class="form" novalidate="novalidate"
                  ref="form" @keypress.enter.prevent="restore">
              <div class="form-group">
                <input class="form-control form-control-solid h-auto py-5 px-6" type="email"
                       :placeholder="$t('login.email')" autocomplete="off" v-model="restore_email"
                       v-on:keyup.enter="restore"/>
              </div>
            </form>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-light-primary font-weight-bold" @click="modal_reset_pasword_show = false">{{ $t('login.cancel') }}</button>
            <button type="button" class="btn btn-primary font-weight-bold" @click="restore" >{{ $t('login.submit') }}</button>
          </div>
        </div>
      </div>
    </div>
    <!-- Modal-->
    <div class="modal fade show" :class="modal_informer_show ? 'show_modal' : ''">
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="infoModalLabel">{{modal_informer.title}}</h5>
            <button type="button" class="close" @click="modal_informer_show = false" aria-label="Close">
              <i aria-hidden="true" class="ki ki-close"></i>
            </button>
          </div>
          <div class="modal-body" style="text-align: center">
            <div v-if="modal_informer.type == 'error'" class="badge badge-danger mx-auto" style="font-size: 20px">
              <font-awesome-icon icon="ban" size="lg"></font-awesome-icon>
            </div>
            <div v-if="modal_informer.type == 'success'" class="badge badge-secondary mx-auto" style="font-size: 20px">
              <font-awesome-icon icon="check" size="lg"></font-awesome-icon>
            </div>
            <br>
            <div class="mx-auto"><b>{{modal_informer.message}}</b></div>
            <br>
            <button type="button" class="mx-auto btn btn-light-primary font-weight-bold"
                    @click="modal_informer_show = false">{{ $t('login.close') }}</button>
          </div>
        </div>
      </div>
    </div>
    <!-- Modal for mobile devices -->
    <AppMobileModal/>
    <!-- END Modal for mobile devices -->
  </div>
</template>


<script>
import {mapMutations, mapGetters} from 'vuex'
import api from '@/plugins/api'
import AppMobileModal from "@/components/core/AppMobileModal";
let cookie = require('vue-cookie');
let bg = require('@/assets/bg/bg-4.jpg')

export default {
  components: {
    AppMobileModal
  },
  computed: {
    ...mapGetters({
      isAuthenticated: 'user/isAuthenticated',
      token: 'user/token',
      identity: 'user/identity',
      logo: 'main/logo',
      selfRegistration: 'main/selfRegistration'
    })
  },
  data() {
    // https://github.com/euvl/vue-notification
    // this.$notify({
    //   group: 'notification',
    //   type: 'warn',
    //   duration: 10000,
    //   title: 'Important message',
    //   text: 'Hello user! This is a notification!'
    // });
    return {
      loading: false,
      modal_reset_pasword_show: false,
      bg: bg,
      restore_email: '',
      username: '',
      password: '',
      remember: false,
      valid: false,
      error: null,
      login_error: null,
      modal_informer_show: false,
      modal_informer: {}
    }
  },
  mounted() {
    // console.log(this.$store.getters['user/token'])
  },
  watch: {
    token (to, from) {
      // обрабатываем изменение параметров маршрута...
      // console.log("isAuthenticated", to, from)
      if (to) {
        this.$router.push(this.$route.query.redirect ? this.$route.query.redirect : '/')
      }
    }
  },
  methods: {
    goHome() {
      this.$router.push('/')
    },
    reset() {
      this.$refs.form.reset()
    },
    switchLocale(locale) {
      if (this.$i18n.locale !== locale) {
        this.$i18n.locale = locale
        cookie.set("locale", locale, {expires: '1Y', path: '/'})
        document.location.reload()
      }
    },
    async login() {
      let self = this
      this.loading = true
      api.post('/v2/auth/login', {username:self.username, password:self.password})
        .then(function (responce0) {
          self.email = ''
          self.password = ''
          self.remember = ''
          self.error = null
          api.get('/v2/users/check', {expand: 'main_spaces,phones'}, {bearer: responce0.data.auth_key})
            .then(responce => {
              self.$store.commit('user/UpdateIdentity', responce.data);
              localStorage.setItem('currentUserId', responce.data.id);
              self.$store.commit('user/CurrentToken', responce.data.auth_key);
              cookie.set("auth_key", responce.data.auth_key, {expires: '1Y'});
              if (responce.data.lang && document.cookie.indexOf('locale') === -1) {
                self.$i18n.locale = responce.data.lang
                cookie.set("locale", self.$i18n.locale, {expires: '1Y'})
              }
              self.loading = false;
              self.$router.push(self.$route.query.redirect ? self.$route.query.redirect : '/');
            }).catch(reason => {
              self.loading = false;
              self.$store.commit('user/UpdateIdentity', {});
              self.$store.commit('user/CurrentToken', null);
              cookie.delete('auth_key');
            })
        }).catch(reason => {
          self.login_error = this.$t('login.error_message');
          self.loading = false
          console.log('Load reason', self.error)
        })
      return undefined
    },
    async restore() {
      let self = this
      this.loading = true
      let data = {
        email: self.restore_email
      }
      api.post('/v2/auth/restore', data, {headers:{'X-Source':api.X_SOURCE_LK}})
          .then(function (responce) {
            self.restore_email = ''
            self.modal_reset_pasword_show = false
            self.modal_informer.title = self.$t('login.password_reset')
            self.modal_informer.message = self.$t('login.recovery_message')
            self.modal_informer.type = 'success'
            self.modal_informer_show = true
            self.error = null
            self.loading = false
            return this
          }).catch(reason => {
            let error = reason.response.data.error.extra.email[0] || 'Validation error'
            self.modal_informer.title = self.$t('login.password_reset')
            self.modal_informer.message = error
            self.modal_informer.type = 'error'
            self.modal_informer_show = true
            self.modal_reset_pasword_show = false
            self.loading = false
            console.error('Load reason', error)
          })
      return undefined
    }
  }
}
</script>

<style>
.show_modal {
  display: block !important;
}
</style>
